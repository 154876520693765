<template>
  <div class="sign-up-page">
    <loginLayout :buttonText="REGISTER_TRANSLATE?.back_btn" :footerText="REGISTER_TRANSLATE?.login?.text" :footerLink="REGISTER_TRANSLATE?.login?.btn" footerRouteName="Login">
      <div v-if="confirmed === 1" class="content">
        <h2 class="r-title-2">{{ REGISTER_TRANSLATE?.title }}</h2>
        <p class="title-hint">
          {{ REGISTER_TRANSLATE?.description }}
        </p>
        <div class="form-inputs">
          <InputText v-model:value.trim="user.name" :errorText="VALIDATION_TRANSLATE?.register?.name" :valid="validName" :label="REGISTER_TRANSLATE?.form?.name" class="required"></InputText>
          <InputText v-model:value.trim="user.last_name" :errorText="VALIDATION_TRANSLATE?.register?.last_name" :valid="validSurname" :label="REGISTER_TRANSLATE?.form?.last_name" class="required"></InputText>
          <InputText v-model:value.trim="user.email" :errorText="VALIDATION_TRANSLATE?.register?.email" :valid="validEmail" :label="REGISTER_TRANSLATE?.form?.email" class="required"></InputText>
          <InputText v-model:value.trim="user.phone" :errorText="VALIDATION_TRANSLATE?.register?.phone" :valid="validPhone" id="telephone" :label="REGISTER_TRANSLATE?.form?.phone" class="required"></InputText>
          <!-- <InputSelect :options="timeList" @selectPressed="onSelectPressed" :label="REGISTER_TRANSLATE?.form?.time_label"></InputSelect> -->
        </div>
        <div class="form-btns">
          <Button @btnSubmit="sendForm">{{ REGISTER_TRANSLATE?.form?.btn }}</Button>
          <span class="policy">
            {{ REGISTER_TRANSLATE?.form?.policy }}
            <a :href="REGISTER_TRANSLATE?.form?.policy_link" target="_blank" class="policy-link">
              {{ REGISTER_TRANSLATE?.form?.policy_color }}
            </a>
          </span>
        </div>
      </div>
      <div v-if="confirmed === 2" class="content confirmed">
        <h2 class="r-title-2 maxi-title">
          {{ REGISTER_TRANSLATE?.message?.first }}
          <!-- <span class="blue-text">{{ REGISTER_TRANSLATE?.message?.color }} {{ user.time }}</span> -->
          <span class="blue-text">{{ REGISTER_TRANSLATE?.message?.color }}</span>
          {{ REGISTER_TRANSLATE?.message?.second }}
        </h2>
        <div class="form-btns">
          <Button @click="toMain">{{ REGISTER_TRANSLATE?.back_btn }}</Button>
        </div>
      </div>
    </loginLayout>
  </div>
</template>

<script>
import Inputmask from "inputmask";
import Button from "../components/parts/button";
import InputText from "../components/parts/input-text";
import loginLayout from "../components/loginLayout";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    Button,
    InputText,
    loginLayout
  },
  data() {
    return {
      confirmed: 1,
      user: {
        name: "",
        last_name: "",
        email: "",
        phone: ""
      }
    };
  },
  computed: {
    ...mapGetters(["REGISTER_TRANSLATE", "VALIDATION_TRANSLATE"]),
    validName() {
      return !this.user.name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/);
    },
    validSurname() {
      return !this.user.last_name.match(/[^А-Яа-яЁёЇїІіЄєҐґa-zA-Z]/);
    },
    validPhone() {
      return !(this.user.phone.length < 19);
    },
    validEmail() {
      const pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
      return pattern.test(this.user.email);
    }
  },
  methods: {
    ...mapActions(["RegisterTranslate", "RegisterCompany", "SummonAlert", "ValidationTranslate"]),
    toMain() {
      this.$router.push({ name: "Main" });
    },
    async sendForm() {
      if (this.validName && this.user.name && this.validSurname && this.user.last_name && this.validPhone && this.validEmail) {
        let temp = { ...this.user };
        temp.phone = temp.phone
          .split("")
          .filter(e => +e || e === "0")
          .join("");
        let res = await this.RegisterCompany(temp);
        if (res == "success") this.clickBtn();
      } else {
        this.SummonAlert({ message: this.REGISTER_TRANSLATE?.form?.alert_error, type: "error" });
      }
    },
    clickBtn() {
      this.confirmed = 2;
    }
  },
  mounted() {
    this.RegisterTranslate(),
      this.ValidationTranslate(),
      Inputmask({
        mask: "+38 (099) 999 99 99",
        placeholder: ""
      }).mask("#telephone");
  },
  watch: {
    user: {
      handler() {
        this.user.phone = document.querySelector("#telephone").value;
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.sign-up-page {
  height: 100%;
  width: 100%;
  .left-box {
    max-width: 710px;
  }
  .content {
    padding: 49px 0 19px 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (min-width: 740px) {
      &.confirmed {
        justify-content: center;
      }
    }
    @media (max-width: 740px) {
      &.confirmed {
        justify-content: flex-start;
      }
    }
    h2 {
      font-weight: 400;
      color: var(--dark-100);

      @media (min-width: 768px) {
        margin-bottom: 8px;
      }

      @media (max-width: 767px) {
        margin-bottom: 7px;
      }
    }
  }
  .title-hint {
    margin-bottom: 50px;
    color: var(--dark-40);
    font-size: 14px;
    letter-spacing: 0.43px;

    @media (max-width: 767px) {
      line-height: 24px;
    }
  }
  .form-inputs {
    display: flex;
    flex-wrap: wrap;
    .main {
      margin-bottom: 35px;
      width: 50%;
    }
    @media (min-width: 740px) {
      .input-box:first-child {
        margin-right: 20px;
      }
    }
    svg {
      color: var(--dark-40);
    }
    &:focus-within {
      svg {
        color: var(--blue-main);
      }
      .not-valid {
        svg {
          color: var(--error);
        }
      }
    }
  }
  .form-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    button {
      margin-right: 20px;
      margin-bottom: 20px;
    }
    button.white:hover {
      background: var(--bg-white);
      color: var(--blue-main);
    }
  }
  @media (max-width: 1024px) and (min-width: 760px) {
    .custom-select {
      margin-right: 20px;
    }
  }
  @media (max-width: 1024px) {
    .form-inputs .main {
      width: 100%;
    }
    .custom-select {
      width: 100%;
    }
    .left-box {
      min-width: 500px;
    }
    .form-inputs {
      flex-wrap: wrap;
      .main:first-child {
        margin-bottom: 30px;
      }
    }
    .content {
      padding-top: 85px;
    }
    .message-bottom {
      bottom: 20px;
    }
  }
  @media (max-width: 764px) {
    .content {
      padding-top: 36px;
    }
    .login-layout header {
      background: var(--white-100);
      padding: 12px 0 12px 21px;
      img {
        margin: auto 0;
      }
    }
    .right-box {
      background-color: var(--bg-main);
      position: absolute;
      top: 0;
      margin-top: 72px;
      max-height: 98px;
      min-height: 98px;
    }
    .content {
      padding-right: 20px;
    }
    .main {
      min-width: 200px;
    }
    .left-box {
      min-width: 320px;
      padding-top: 170px;
    }
    .message-bottom {
      top: 674px;
      p.m-text-des {
        padding-bottom: 20px;
      }
    }
  }
  @media (max-width: 370px) {
    .form-btns {
      flex-direction: column;
      align-items: flex-start;
      .white {
        margin-top: 20px;
      }
    }
  }
  .policy {
    display: block;
    color: var(--dark-40);
    font-size: 12px;
    margin-bottom: 20px;
    letter-spacing: 0.5px;

    &-link {
      color: var(--blue-main);
      text-decoration: none;
    }
  }
  .blue-text {
    color: var(--blue-main);
    font-family: "Aeonik Pro Regular", sans-serif;
  }

  .maxi-title {
    max-width: 494px;
  }
}
</style>
